import React from "react";
import { useState } from "react";
import "./UserPage.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
function UserPage() {
  const emailUserData = [
    {
      id: 1,
      userImage: "/Images/Refferals/userImage.svg",
      userName: "George",
      email: "george_1@magnus.com",
      refferalcode: 1534,
    },
    {
      id: 1,
      userImage: "/Images/Refferals/userImage.svg",
      userName: "George",
      email: "george_1@magnus.com",
      refferalcode: 1534,
    },
    {
      id: 1,
      userImage: "/Images/Refferals/userImage.svg",
      userName: "George",
      email: "george_1@magnus.com",
      refferalcode: 1534,
    },
    {
      id: 1,
      userImage: "/Images/Refferals/userImage.svg",
      userName: "George",
      email: "george_1@magnus.com",
      refferalcode: 1534,
    },
    {
      id: 1,
      userImage: "/Images/Refferals/userImage.svg",
      userName: "George",
      email: "george_1@magnus.com",
      refferalcode: 1534,
    },
    {
      id: 1,
      userImage: "/Images/Refferals/userImage.svg",
      userName: "George",
      email: "george_1@magnus.com",
      refferalcode: 1534,
    },
    {
      id: 1,
      userImage: "/Images/Refferals/userImage.svg",
      userName: "George",
      email: "george_1@magnus.com",
      refferalcode: 1534,
    },
    {
      id: 1,
      userImage: "/Images/Refferals/userImage.svg",
      userName: "George",
      email: "george_1@magnus.com",
      refferalcode: 1534,
    },
    {
      id: 1,
      userImage: "/Images/Refferals/userImage.svg",
      userName: "George",
      email: "george_1@magnus.com",
      refferalcode: 1534,
    },
    {
      id: 1,
      userImage: "/Images/Refferals/userImage.svg",
      userName: "George",
      email: "george_1@magnus.com",
      refferalcode: 1534,
    },
    {
      id: 1,
      userImage: "/Images/Refferals/userImage.svg",
      userName: "George",
      email: "george_1@magnus.com",
      refferalcode: 1534,
    },
    {
      id: 1,
      userImage: "/Images/Refferals/userImage.svg",
      userName: "George",
      email: "george_1@magnus.com",
      refferalcode: 1534,
    },
    {
      id: 1,
      userImage: "/Images/Refferals/userImage.svg",
      userName: "George",
      email: "george_1@magnus.com",
      refferalcode: 1534,
    },
    {
      id: 1,
      userImage: "/Images/Refferals/userImage.svg",
      userName: "George",
      email: "george_1@magnus.com",
      refferalcode: 1534,
    },
    {
      id: 1,
      userImage: "/Images/Refferals/userImage.svg",
      userName: "George",
      email: "george_1@magnus.com",
      refferalcode: 1534,
    },
    {
      id: 1,
      userImage: "/Images/Refferals/userImage.svg",
      userName: "George",
      email: "george_1@magnus.com",
      refferalcode: 1534,
    },
  ];
  const telegramUserData = [
    {
      id: 1,
      userImage: "/Images/Refferals/userImage.svg",
      userName: "George",
      telegramUser: "@george",
      email: "george_1@magnus.com",
      refferalcode: 1534,
    },
    {
      id: 1,
      userImage: "/Images/Refferals/userImage.svg",
      userName: "George",
      telegramUser: "@george",
      email: "george_1@magnus.com",
      refferalcode: 1534,
    },
    {
      id: 1,
      userImage: "/Images/Refferals/userImage.svg",
      userName: "George",
      telegramUser: "@george",
      email: "george_1@magnus.com",
      refferalcode: 1534,
    },
    {
      id: 1,
      userImage: "/Images/Refferals/userImage.svg",
      userName: "George",
      telegramUser: "@george",
      email: "george_1@magnus.com",
      refferalcode: 1534,
    },
    {
      id: 1,
      userImage: "/Images/Refferals/userImage.svg",
      userName: "George",
      telegramUser: "@george",
      email: "george_1@magnus.com",
      refferalcode: 1534,
    },
    {
      id: 1,
      userImage: "/Images/Refferals/userImage.svg",
      userName: "George",
      telegramUser: "@george",
      email: "george_1@magnus.com",
      refferalcode: 1534,
    },
    {
      id: 1,
      userImage: "/Images/Refferals/userImage.svg",
      userName: "George",
      telegramUser: "@george",
      email: "george_1@magnus.com",
      refferalcode: 1534,
    },
    {
      id: 1,
      userImage: "/Images/Refferals/userImage.svg",
      userName: "George",
      telegramUser: "@george",
      email: "george_1@magnus.com",
      refferalcode: 1534,
    },
    {
      id: 1,
      userImage: "/Images/Refferals/userImage.svg",
      userName: "George",
      telegramUser: "@george",
      email: "george_1@magnus.com",
      refferalcode: 1534,
    },
    {
      id: 1,
      userImage: "/Images/Refferals/userImage.svg",
      userName: "George",
      telegramUser: "@george",
      email: "george_1@magnus.com",
      refferalcode: 1534,
    },
    {
      id: 1,
      userImage: "/Images/Refferals/userImage.svg",
      userName: "George",
      telegramUser: "@george",
      email: "george_1@magnus.com",
      refferalcode: 1534,
    },
    {
      id: 1,
      userImage: "/Images/Refferals/userImage.svg",
      userName: "George",
      telegramUser: "@george",
      email: "george_1@magnus.com",
      refferalcode: 1534,
    },
    {
      id: 1,
      userImage: "/Images/Refferals/userImage.svg",
      userName: "George",
      telegramUser: "@george",
      email: "george_1@magnus.com",
      refferalcode: 1534,
    },
    {
      id: 1,
      userImage: "/Images/Refferals/userImage.svg",
      userName: "George",
      telegramUser: "@george",
      email: "george_1@magnus.com",
      refferalcode: 1534,
    },
    {
      id: 1,
      userImage: "/Images/Refferals/userImage.svg",
      userName: "George",
      telegramUser: "@george",
      email: "george_1@magnus.com",
      refferalcode: 1534,
    },
    {
      id: 1,
      userImage: "/Images/Refferals/userImage.svg",
      userName: "George",
      telegramUser: "@george",
      email: "george_1@magnus.com",
      refferalcode: 1534,
    },
  ];
  const [activeButton, setActiveButton] = useState("Email");
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };
  return (
    <div className="users__main__container">
      <div className="users__main__container1">
        <div className="users__buttons-container">
          <div className="users__button-wrapper">
            <button
              className={`btnusers ${
                activeButton === "Email"
                  ? "active_button_user"
                  : "transparent__button_user"
              }`}
              onClick={() => handleButtonClick("Email")}
            >
              Email
            </button>

            <button
              className={`btnusers ${
                activeButton === "Telegram"
                  ? "active_button_user"
                  : "transparent__button_user"
              }`}
              onClick={() => handleButtonClick("Telegram")}
            >
              Telegram
            </button>
          </div>
        </div>

        <TableContainer
          className="users__table__main__container"
          component={Paper}
          style={{
            backgroundColor: "rgba(188,191,204, 0.1)",
            boxShadow: "none",
          }}
        >
          <Table
            className="for-border"
            sx={{ minWidth: 650, backgroundColor: "rgba(188,191,204, 0.1)" }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell className="users__head__main">#</TableCell>
                <TableCell className="users__head__main">User Image</TableCell>
                <TableCell className="users__head__main">Name</TableCell>
                {activeButton === "Telegram" && (
                  <TableCell className="users__head__main">
                    Telegram Username
                  </TableCell>
                )}
                <TableCell className="users__head__main">Email</TableCell>
                <TableCell className="users__head__main">
                  {activeButton === "Telegram"
                    ? "Referral ID"
                    : "Referral Code"}
                </TableCell>

                <TableCell className="users__head__main">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="users__table__body">
              {(activeButton === "Email"
                ? emailUserData
                : telegramUserData
              ).map((user, index) => (
                <TableRow key={user.id}>
                  <TableCell className="users__index_1">{index + 1}</TableCell>
                  <TableCell className="users__user__image">
                    <img
                      className="user__image_1"
                      src={user.userImage}
                      alt={`${user.userName}'s avatar`}
                    />
                  </TableCell>
                  <TableCell className="users__user_name">
                    {user.userName}
                  </TableCell>
                  {activeButton === "Telegram" && (
                    <TableCell className="users__telegram_user">
                      {user.telegramUser}
                    </TableCell>
                  )}
                  <TableCell className="users__email">{user.email}</TableCell>
                  <TableCell className="users__refferalcode">
                    {user.refferalcode}
                  </TableCell>
                  <TableCell className="users__button">
                    <button className="user__delete__button">Delete</button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default UserPage;
