import React from "react";
import { useState } from "react";
import "./Ranking.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function Ranking() {
    const rankingData = [
        {
          id: 1,
          userImage: '/Images/Refferals/userImage.svg',
          userName: 'George',
          level: 'Level 1',
          referral: 13,
          taskCompleted: 5,
          xmagEarned: 15000,
        },
        {
            id: 1,
            userImage: '/Images/Refferals/userImage.svg',
            userName: 'George',
            level: 'Level 2',
            referral: 13,
            taskCompleted: 5,
            xmagEarned: 15000,
        },
        {
            id: 1,
            userImage: '/Images/Refferals/userImage.svg',
            userName: 'George',
            level: 'Level 3',
            referral: 13,
            taskCompleted: 5,
            xmagEarned: 15000,
        },
        {
            id: 1,
            userImage: '/Images/Refferals/userImage.svg',
            userName: 'George',
            level: 'Level 4',
            referral: 13,
            taskCompleted: 5,
            xmagEarned: 15000,
        },
        {
            id: 1,
            userImage: '/Images/Refferals/userImage.svg',
            userName: 'George',
            level: 'Level 1',
            referral: 13,
            taskCompleted: 5,
            xmagEarned: 15000,
          },
          {
              id: 1,
              userImage: '/Images/Refferals/userImage.svg',
              userName: 'George',
              level: 'Level 2',
              referral: 13,
              taskCompleted: 5,
              xmagEarned: 15000,
          },
          {
              id: 1,
              userImage: '/Images/Refferals/userImage.svg',
              userName: 'George',
              level: 'Level 3',
              referral: 13,
              taskCompleted: 5,
              xmagEarned: 15000,
          },
          {
              id: 1,
              userImage: '/Images/Refferals/userImage.svg',
              userName: 'George',
              level: 'Level 4',
              referral: 13,
              taskCompleted: 5,
              xmagEarned: 15000,
          },
          {
            id: 1,
            userImage: '/Images/Refferals/userImage.svg',
            userName: 'George',
            level: 'Level 1',
            referral: 13,
            taskCompleted: 5,
            xmagEarned: 15000,
          },
          {
              id: 1,
              userImage: '/Images/Refferals/userImage.svg',
              userName: 'George',
              level: 'Level 2',
              referral: 13,
              taskCompleted: 5,
              xmagEarned: 15000,
          },
          {
              id: 1,
              userImage: '/Images/Refferals/userImage.svg',
              userName: 'George',
              level: 'Level 3',
              referral: 13,
              taskCompleted: 5,
              xmagEarned: 15000,
          },
          {
              id: 1,
              userImage: '/Images/Refferals/userImage.svg',
              userName: 'George',
              level: 'Level 4',
              referral: 13,
              taskCompleted: 5,
              xmagEarned: 15000,
          },{
            id: 1,
            userImage: '/Images/Refferals/userImage.svg',
            userName: 'George',
            level: 'Level 1',
            referral: 13,
            taskCompleted: 5,
            xmagEarned: 15000,
          },
          {
              id: 1,
              userImage: '/Images/Refferals/userImage.svg',
              userName: 'George',
              level: 'Level 2',
              referral: 13,
              taskCompleted: 5,
              xmagEarned: 15000,
          },
          {
              id: 1,
              userImage: '/Images/Refferals/userImage.svg',
              userName: 'George',
              level: 'Level 3',
              referral: 13,
              taskCompleted: 5,
              xmagEarned: 15000,
          },
          {
              id: 1,
              userImage: '/Images/Refferals/userImage.svg',
              userName: 'George',
              level: 'Level 4',
              referral: 13,
              taskCompleted: 5,
              xmagEarned: 15000,
          },
    ]
  const [activeButton, setActiveButton] = useState("Bronze");
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };
  return (
    <div className="ranking__main__container">
      <div className="ranking__main__container1">
        <div className="ranking__button-group">
          <div className="ranking__button-group1">
            <button
              className={`button__ranking ${
                activeButton === "Bronze"
                  ? "button__ranking-active"
                  : "button__ranking-transparent"
              }`}
              onClick={() => handleButtonClick("Bronze")}
            >
              Bronze
            </button>

            <button
              className={`button__ranking ${
                activeButton === "Silver"
                  ? "button__ranking-active"
                  : "button__ranking-transparent"
              }`}
              onClick={() => handleButtonClick("Silver")}
            >
              Silver
            </button>

            <button
              className={`button__ranking ${
                activeButton === "Gold"
                  ? "button__ranking-active"
                  : "button__ranking-transparent"
              }`}
              onClick={() => handleButtonClick("Gold")}
            >
              Gold
            </button>

            <button
              className={`button__ranking ${
                activeButton === "Platinum"
                  ? "button__ranking-active"
                  : "button__ranking-transparent"
              }`}
              onClick={() => handleButtonClick("Platinum")}
            >
              Platinum
            </button>

            <button
              className={`button__ranking ${
                activeButton === "Diamond"
                  ? "button__ranking-active"
                  : "button__ranking-transparent"
              }`}
              onClick={() => handleButtonClick("Diamond")}
            >
              Diamond
            </button>
          </div>
        </div>
        <TableContainer
        className="ranking__table__main__container"
        component={Paper}
        style={{
          backgroundColor: "rgba(188,191,204, 0.1)",
          boxShadow: "none",
        }}
      >
        <Table
          className="for-border"
          sx={{ minWidth: 650, backgroundColor: "rgba(188,191,204, 0.1)" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell className="ranking__head__main">#</TableCell>
              <TableCell className="ranking__head__main">User Image</TableCell>
              <TableCell className="ranking__head__main">User</TableCell>
              <TableCell className="ranking__head__main">Level</TableCell>
              <TableCell className="ranking__head__main">Referral</TableCell>
              <TableCell className="ranking__head__main">Tasks Completed</TableCell>
              <TableCell className="ranking__head__main">xMAG Earned</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="ranking__table__body">
          {rankingData.map((ranking, index) => (
            <TableRow key={ranking.id}>
              <TableCell className="ranking__index_1">{index + 1}</TableCell>
              <TableCell className="ranking__user__image">
                <img className="user__image_1"
                  src={ranking.userImage}
                  alt={`${ranking.userName}'s avatar`}
                />
              </TableCell>
              <TableCell  className="ranking__user_name" >{ranking.userName}</TableCell>
              <TableCell  className="ranking__level" >{ranking.level}</TableCell>
              <TableCell  className="ranking__refferal">{ranking.referral}</TableCell>
              <TableCell className="ranking__taskcompleted">{ranking.taskCompleted}</TableCell>
              <TableCell className="ranking__xmag">{ranking.xmagEarned}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        </Table>
      </TableContainer>
      </div>
    </div>
  );
}

export default Ranking;
