import React from "react";
import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { DropzoneArea } from "material-ui-dropzone";
import { useNavigate } from "react-router-dom";
import "./EditTask.css";
function EditTask() {
  const [img, setImg] = useState("");
  const [imageSource, setImageSource] = useState();
  const [selectedGroup, setSelectedGroup] = useState("Select Group");
  const navigate = useNavigate();
  function handleTaskClick() {
    navigate("/");
  }

  const handleSelectGroup = (group) => {
    setSelectedGroup(group);
  };

  return (
    <div className="edit__task__main__container">
      <div className="edit__task__right__container">
        <div className="edit__task__main__container1">
          <p className="add__task__heading_1">Add Task</p>
          <div className="edit__task__form__container">
            <div className="edit__task__form__container1">
              <p className="edit__task__form_label"> Task Title</p>
              <input
                className="edit__task__form_placeholder"
                type="text"
                placeholder="Enter Title of the Task"
              />
            </div>
            <div className="edit__task__form__container1">
              <p className="edit__task__form_label">Task Description</p>
              <textarea
                className="edit__task__textarea_placeholder"
                placeholder="Enter Description of the Task"
              />
            </div>
            <div className="edit__task__form__container1">
              <p className="edit__task__form_label">xMAG Task Amount</p>
              <input
                className="edit__task__form_placeholder"
                type="text"
                placeholder="Enter xMAG Amount of the Task"
              />
            </div>
            <div className="edit__task__form__container1">
              <p className="edit__task__form_label">Task URL Link</p>
              <input
                className="edit__task__form_placeholder"
                type="text"
                placeholder="Input Task URL Link"
              />
            </div>
            <div className="edit__task__form__container1">
              <p className="edit__task__form_label">Choose Task Group</p>
              <div className="edit__task__placeholder_container">
                <input
                  className="edit__task__form_placeholder-1"
                  type="text"
                  value={selectedGroup}
                  readOnly
                  style={{
                    color:
                      selectedGroup === "Select Group" ? "#bcbfcc" : "#1e1e24",
                  }}
                />
                <Dropdown className="custom-dropdown-1">
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="dropdown-toggle-custom"
                  >
                    <img
                      src="/Images/EditTask/dropdown.svg"
                      alt="dropdown icon"
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dropdown-menu-custom">
                    <Dropdown.Item
                      className="dropdown__heading_1"
                      onClick={() => handleSelectGroup("New Task")}
                    >
                      New Task
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="dropdown__heading_1"
                      onClick={() => handleSelectGroup("Featured Task")}
                    >
                      Featured Task
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          <div className="edit__task__button__container">
            <button className="edit__task__publish__button">Publish</button>
            <button onClick={handleTaskClick} className="edit__task__cancel__button">Cancel</button>
          </div>
        </div>
        <div className="upload__task__container">
          <div className="upload__task__container1">
            <p className="upload__task__heading">
            Upload Task Image
            </p>
            <p className="upload__task__imagesize__heading">
            Image size: 52x52
            </p>
          </div>
          <DropzoneArea
                  id="upload_image"
                  filesLimit={1}
                  acceptedFiles={["image/*"]}
                  maxFileSize={5000000}
                  showAlerts={["error", "info"]}
                  dropzoneText="Drag & Drop or"
                  value={img}
                  onChange={(files) => {
                    setImageSource(files[0]);
                  }}
                  onDropRejected={(files) => {
                    alert("File rejected: " + files[0].name);
                  }}
                />
        </div>
      </div>
    </div>
  );
}

export default EditTask;
