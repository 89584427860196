import React, { Act, useState , useEffect } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
  } from '@mui/material';
  import './update.css'
const UpdatePage = () => {
    const [Act, SetAct] = useState("A");
    const handleClick = (active) => {
      SetAct(active);
    };
    const data = [
        {
          id: 1,
          title: 'Lorem Ipsum',
          description: 'Norem ipsum dolor sit amet consectetur',
          date: '11/10/24',
          time: '11:04:56',
        },
        {
            id: 2,
            title: 'Lorem Ipsum',
            description: 'Norem ipsum dolor sit amet consectetur',
            date: '11/10/24',
            time: '11:04:56',
          },
          {
            id: 3,
            title: 'Lorem Ipsum',
            description: 'Norem ipsum dolor sit amet consectetur',
            date: '11/10/24',
            time: '11:04:56',
          },
          {
            id: 4,
            title: 'Lorem Ipsum',
            description: 'Norem ipsum dolor sit amet consectetur',
            date: '11/10/24',
            time: '11:04:56',
          },
          {
            id: 5,
            title: 'Lorem Ipsum',
            description: 'Norem ipsum dolor sit amet consectetur',
            date: '11/10/24',
            time: '11:04:56',
          },
          {
            id: 6,
            title: 'Lorem Ipsum',
            description: 'Norem ipsum dolor sit amet consectetur',
            date: '11/10/24',
            time: '11:04:56',
          },
          {
            id: 7,
            title: 'Lorem Ipsum',
            description: 'Norem ipsum dolor sit amet consectetur',
            date: '11/10/24',
            time: '11:04:56',
          },
          {
            id: 8,
            title: 'Lorem Ipsum',
            description: 'Norem ipsum dolor sit amet consectetur',
            date: '11/10/24',
            time: '11:04:56',
          },
          {
            id: 9,
            title: 'Lorem Ipsum',
            description: 'Norem ipsum dolor sit amet consectetur',
            date: '11/10/24',
            time: '11:04:56',
          },
          {
            id: 10,
            title: 'Lorem Ipsum',
            description: 'Norem ipsum dolor sit amet consectetur',
            date: '11/10/24',
            time: '11:04:56',
          },
          {
            id: 11,
            title: 'Lorem Ipsum',
            description: 'Norem ipsum dolor sit amet consectetur',
            date: '11/10/24',
            time: '11:04:56',
          },
          {
            id: 12,
            title: 'Lorem Ipsum',
            description: 'Norem ipsum dolor sit amet consectetur',
            date: '11/10/24',
            time: '11:04:56',
          },
          {
            id: 13,
            title: 'Lorem Ipsum',
            description: 'Norem ipsum dolor sit amet consectetur',
            date: '11/10/24',
            time: '11:04:56',
          },
          {
            id: 14,
            title: 'Lorem Ipsum',
            description: 'Norem ipsum dolor sit amet consectetur',
            date: '11/10/24',
            time: '11:04:56',
          },
          {
            id: 15,
            title: 'Lorem Ipsum',
            description: 'Norem ipsum dolor sit amet consectetur',
            date: '11/10/24',
            time: '11:04:56',
          },
          {
            id: 16,
            title: 'Lorem Ipsum',
            description: 'Norem ipsum dolor sit amet consectetur',
            date: '11/10/24',
            time: '11:04:56',
          },
        // Repeat this object for all your rows or fetch data from API
      ];
      const [activeButton, setActiveButton] = useState("News");
      const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName);
      };
  return (
    <div className="main">
        <div className="buttons-container">
        <div className="button-wrapper">
          <button
            className={`btn ${
              activeButton === "News" ? "active" : "transparent"
            }`}
            onClick={() => handleButtonClick("News")}
          >
            News
          </button>

          <button
            className={`btn ${
              activeButton === "Roadmap" ? "active" : "transparent"
            }`}
            onClick={() => handleButtonClick("Roadmap")}
          >
            Roadmap
          </button>
        </div>
      </div>
          <TableContainer component={Paper} sx={{ mt: 4 }} className="tableMain">
      <Table aria-label="custom table">
        <TableHead  className="header_style_Main">
          <TableRow>
            <TableCell  className="header_Item_styling">#</TableCell>
            <TableCell className="header_Title_styling">Title</TableCell>
            <TableCell className="header_Description_styling">Description</TableCell>
            <TableCell className="header_Date_styling">Date</TableCell>
            <TableCell className="header_time_styling">Time</TableCell>
            <TableCell className="header_Item_styling"    align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.id}>
              <TableCell   className="Class_id">{row.id}</TableCell>
              <TableCell className="class__update__title">
                <strong   className="Class_Title"  >{row.title}</strong>
              </TableCell>
              <TableCell className="ClassDescription">{row.description}</TableCell>
              <TableCell className="Class_date">{row.date}</TableCell>
              <TableCell className="Class_Time">{row.time}</TableCell>
              <TableCell className="button__container__update_delete" align="center">
                <button
             className="Button_Styling"
                >
                  Delete
                </button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
        </div>
  )
}
export default UpdatePage