import { configureStore } from "@reduxjs/toolkit";
import profile from "./reducer/profile";
import getUserDetail from "./reducer/getUserDetail";
import getUserProfile from "./reducer/getUserProfile";

export const store = configureStore({
  reducer: {
    profile: profile,
    user: getUserDetail,
    getProfile:getUserProfile
  },
});
