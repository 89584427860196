import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./TaskGroup.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import playstoreImage from "../../assets/taskimages/playstore.svg";
import appstoreImage from "../../assets/taskimages/appstore.svg";
import twitterxImage from "../../assets/taskimages/twitter.svg";
import discordImage from "../../assets/taskimages/discord.svg";
import telegramImage from "../../assets/taskimages/telegram.svg";
import facebookImage from "../../assets/taskimages/facebook.svg";
import watchlistImage from "../../assets/taskimages/watchlist.svg";
import TrustPilotImage from "../../assets/taskimages/staricon.svg";
import LinkedinImage from "../../assets/taskimages/linkedin.svg";
import RedditImage from "../../assets/taskimages/reddit.svg";
import editIcon from "../../assets/taskimages/edit1.svg";
import deleteIcon from "../../assets/taskimages/delete.svg";
import DeleteTaskModal from "../../Components/Modal/DeleteTask";
function TaskGroup() {
  const tasks = [
    {
      id: 1,
      image: playstoreImage,
      title: "Playstore",
      description: "Leave a rating and review",
      completion: 15,
      rewards: "10,000",
      started: "11/10/24",
      ended: "15/10/24",
    },
    {
      id: 2,
      image: appstoreImage,
      title: "App Store",
      description: "Leave a rating and review",
      completion: 15,
      rewards: "10,000",
      started: "11/10/24",
      ended: "15/10/24",
    },
    {
      id: 3,
      image: twitterxImage,
      title: "Twitter X",
      description: "Follow Magnus X",
      completion: 15,
      rewards: "10,000",
      started: "11/10/24",
      ended: "15/10/24",
    },
    {
      id: 4,
      image: discordImage,
      title: "Discord",
      description: "Join Magnus Discord",
      completion: 15,
      rewards: "10,000",
      started: "11/10/24",
      ended: "15/10/24",
    },
    {
      id: 5,
      image: discordImage,
      title: "Discord",
      description: "Boost Discord Server",
      completion: 15,
      rewards: "10,000",
      started: "11/10/24",
      ended: "15/10/24",
    },
    {
      id: 6,
      image: telegramImage,
      title: "Telegram",
      description: "Join Telegram",
      completion: 15,
      rewards: "10,000",
      started: "11/10/24",
      ended: "15/10/24",
    },
    {
      id: 7,
      image: facebookImage,
      title: "Facebook",
      description: "Follow Magnus FB",
      completion: 15,
      rewards: "10,000",
      started: "11/10/24",
      ended: "15/10/24",
    },
    {
      id: 8,
      image: watchlistImage,
      title: "Watchlist",
      description: "Follow & add to Watchlist",
      completion: 15,
      rewards: "10,000",
      started: "11/10/24",
      ended: "15/10/24",
    },
    {
      id: 9,
      image: TrustPilotImage,
      title: "TrustPilot",
      description: "Rate & Review on TrustPilot",
      completion: 15,
      rewards: "10,000",
      started: "11/10/24",
      ended: "15/10/24",
    },
    {
      id: 9,
      image: LinkedinImage,
      title: "Linkedin",
      description: "Follow Magnus Linkedin",
      completion: 15,
      rewards: "10,000",
      started: "11/10/24",
      ended: "15/10/24",
    },
    {
      id: 9,
      image: RedditImage,
      title: "Reddit",
      description: "Join Magnus Reddit",
      completion: 15,
      rewards: "10,000",
      started: "11/10/24",
      ended: "15/10/24",
    },
  ];
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState("Live");
  const [modalShow, setModalShow] = useState(false);
  const handleClose = () => setModalShow(false);
  function handleEditTaskgroupClick() {
    navigate("/editTaskGroup");
  }
  return (
    <div className="task__group__main_container">
      <TableContainer
        className="taskgroup__table__main__container"
        component={Paper}
        style={{
          backgroundColor: "rgba(188,191,204, 0.1)",
          boxShadow: "none",
        }}
      >
        <Table
          className="for-border"
          sx={{ minWidth: 650, backgroundColor: "rgba(188,191,204, 0.1)" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell className="table__head__main1">Task Image</TableCell>
              <TableCell className="table__head__main1">
                Title / Description
              </TableCell>
              <TableCell className="table__head__main1">
                Task Completion
              </TableCell>
              <TableCell className="table__head__main1">Rewards</TableCell>
              <TableCell className="table__head__main1">Date Started</TableCell>
              <TableCell className="table__head__main1">Date Ended</TableCell>
              <TableCell className="table__head__main1">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="table__task__body">
            {tasks.map((task) => (
              <TableRow key={task.id}>
                <TableCell
                  className="taskgroup__table__title_image_container"
                  align="center"
                >
                  <div className="taskgroup__title__image_container">
                    <img
                      className="taskgroup__title__image"
                      src={task.image}
                      alt={task.title}
                    />
                  </div>
                </TableCell>
                <TableCell className="title__taskgroup__table">
                  {task.title}
                  <span className="subtitle__taskgroup__table">
                    <br />
                    {task.description}
                  </span>
                </TableCell>
                <TableCell className="table__taskgroup__completion">
                  {task.completion}
                </TableCell>
                <TableCell className="table__taskgroup__rewards">
                  {task.rewards}
                </TableCell>
                <TableCell className="table__taskgroup_start_date">
                  {task.started}
                </TableCell>
                <TableCell className="table__taskgroup__end_date">
                  {task.ended}
                </TableCell>
                <TableCell className="taskgroup__image_icon ">
                  <div style={{ display: "flex" }}>
                    <img onClick={handleEditTaskgroupClick} src={editIcon} alt="edit" />

                    <img
                      onClick={() => setModalShow(true)}
                      className="delete__taskgroup__icon"
                      src={deleteIcon}
                      alt="delete"
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <DeleteTaskModal show={modalShow} handleClose={handleClose} />
    </div>
  );
}

export default TaskGroup;
