import "./SideDrawer.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Header from "../Header/Header";
// import useAuth from "../../hooks/Web3Connection/useAuth";
// import Web3Modal from "../modal/Web3modal/Web3Modal";
import Avatar from "@mui/material/Avatar";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
// import { useWeb3React } from "@web3-react/core";
import {
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";
import { Menu, MenuItem, makeStyles } from "@mui/material";
// import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
// import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";

import { useMediaQuery } from "react-responsive";
import { borderBottom, borderRight, display, minHeight } from "@mui/system";

const drawerWidth = 280;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    height: "100%",
    overflowX: "hidden",
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
      fontFamily: "Montserrat",
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  backgroundColor: "white",
  zIndex: "2",
}));

const AvatarTopDiv = styled("div")(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(1),
  right: theme.spacing(1),
  zIndex: theme.zIndex.drawer + 1,
  [theme.breakpoints.up("md")]: {
    width: "100%",
    minWidth: 768,
  },
  [theme.breakpoints.down("sm")]: {
    top: theme.spacing(1),
    left: theme.spacing(1),
    right: "auto",
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "flex-end",

    gap: theme.spacing(2),
  },
}));
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),

  ...theme.mixins.toolbar,
  justifyContent: "space-between",
  padding: "26px 24px",
  borderBottom: "none",
  zIndex: "2",
}));

const DrawerFooter = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),

  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));
const themeMui = createTheme({
  typography: {
    fontFamily: "Montserrat-Medium",
    fontWeight: "500",
    fontSize: "14px",
    color: "#BcBfCC",
  },
});
function getHeaderName(pathname) {
  switch (pathname) {
    case "/":
      return "Tasks";
    case "/editTask":
      return "Tasks";
    case "/taskGroup":
      return "Tasks";
    case "/editTaskGroup":
      return "Tasks";
    case "/refferals":
      return "Referrals";
    case "/ranking":
      return "Ranking";
    case "/users":
      return "Users";
    case "/update":
      return "Updates";
    default:
      return "Tasks";
  }
}
export default function SideDrawer({ children, showSidebar, PageName }) {
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState("");
  const [active, setactive] = useState("");
  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = () => {
    setExpanded(!expanded);
  };

  const handleToggle = () => {
    setOpen(!open);
  };
  const handle_accordian = (abc) => {
    setactive(active === abc ? "" : abc);
  };

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const theme = useTheme();
  const isMobile = useMediaQuery({
    query: "(max-width: 1064px)",
  });

  useEffect(() => {
    if (isMobile) {
      setOpen(false);
    }
  }, [isMobile]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedotherItem, setSelectedOtherItem] = useState(null);

  const handleListItemClick = (index) => {
    console.log("clicked", index);
    setSelectedOtherItem(null);
    setSelectedItem(index);
  };
  const handleOtherListItemClick = (index) => {
    console.log("clicked", index);
    setSelectedItem(null);
    setSelectedOtherItem(index);
  };

  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [mobileOpen, setMobileOpen] = React.useState(true);
  const [isOpen, setIsOpen] = useState(false);

  const handleDrawerOpen = () => {
    console.log("this is clicked");
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setMobileOpen(!mobileOpen);
  };
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [selected, setSelected] = useState(false);
  const opens = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    console.log("closing");
  };

  const handleLogout = () => {
    handleClose();
  };
  const handleLogoutMain = () => {
    localStorage.clear();
    navigate("/");
  };

  const handleSignIn = () => {
    localStorage.clear();
    navigate("/");
  };
  const handleSelection = () => {
    setSelected(!selected); // Toggle the selection state
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const hideRoutes = ["/refferals", "/users", "/ranking", "/update", "/editTaskGroup", "/taskedit"];
  const menuItems = [
    { text: "Dashboard", path: "/", disabled: false },
    // { text: "Account", path: "/Dashboard/UserProfile", disabled: false },
    { text: "Tasks", path: "/", disabled: false },
    { text: "Refferals", path: "/refferals", disabled: false },
    { text: "Users", path: "/users", disabled: false },
    { text: "Ranking", path: "/ranking", disabled: false },
    { text: "Updates", path: "/update", disabled: false },

    // {
    //   text: "Settings",
    //   path: "/",
    //   disabled: false,
    // },

    // { text: "Partner Program", path: "/Dashboard/Admin", disabled: false },
  ];
  const dashicons = [
    "/Images/Dashboard/dimage.svg",
    "/Images/Dashboard/task.svg",
    "/Images/Dashboard/referrals.svg",
    "/Images/Dashboard/user.svg",
    "/Images/Dashboard/ranking.svg",
    "/Images/Dashboard/updates.svg",
    // "/Images/Dashboard/wealth.svg",
    // "/Images/Dashboard/latestnews.svg",
    // "/Images/Dashboard/Airdrops.svg",
  ];
  const activeDashicons = [
    "/Images/Dashboard/activedash.svg",
    "/Images/Dashboard/activetask.svg",
    "/Images/Dashboard/activerefferal.svg",
    "/Images/Dashboard/activeranking.svg",
    "/Images/Dashboard/activeranking1.svg",
    "/Images/Dashboard/activeupdate.svg",
  ];
  const OthermenuItems = [
    // { text: "Help", path: "/" },
    // { text: "Feedback", path: "/" },
  ];
  const otherdashicons = [
    "/Images/Dashboard/help.svg",
    "/Images/Dashboard/feedback.svg",
  ];

  let users = JSON.parse(localStorage.getItem("user"));

  let filteredMenuItems = [];
  let filteredOtherMenuItems = [];

  if (users?.role === 1) {
    filteredMenuItems = menuItems.filter((item) =>
      ["Home", "File Manager", "Admin"].includes(item.text)
    );
  } else {
    filteredMenuItems = menuItems.filter((item) => item.text !== "Admin");
    filteredOtherMenuItems = OthermenuItems.filter(
      (item) => item.text !== "Admin"
    );
  }

  // for connect wallet
  const [wallet, SetWallet] = useState(false);
  // const { account, chainId } = useWeb3React();
  // const { logout } = useAuth();
  const connectorId =
    typeof window !== "undefined" ? localStorage.getItem("connectorId") : null;
  async function handleConnect() {
    // if (!account) {
    //   SetWallet(true);
    // } else {
    //   await logout(connectorId);
    //   localStorage.clear();
    // }
  }

  return (
    <>
      <ThemeProvider theme={themeMui}>
        {showSidebar && (
          <Box
            sx={{
              paddingLeft: open
                ? isMobile
                  ? "0px" // Padding when the drawer is open on mobile
                  : `${drawerWidth}px` // Padding when the drawer is open on larger screens
                : isMobile
                ? "0px" // Padding when the drawer is closed on mobile
                : "28px", // Padding when the drawer is closed on larger screens
              transition: "padding-left 0.3s ease", // Smooth transition when opening/closing drawer
            }}
          >
            <CssBaseline />
            <AppBar
              position="fixed"
              open={open}
              style={{
                padding: "5px 30px",
                boxShadow: "none",
                borderBottom: "1px solid #BCBFCC99",
              }}
              className="sidebar__top-main"
            >
              <Toolbar
                style={{
                  display: "flex",
                  position: "relative",
                  zIndex: "2",
                  padding: "0px",
                  minHeight: "59px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <IconButton
                  color="#0000"
                  aria-label="open drawer"
                  onClick={isMobile ? handleDrawerToggle : handleDrawerOpen}
                  edge="start"
                  sx={{
                    mr: 2,
                    ...(open && { display: "none" }),
                    position: "absolute",
                    zIndex: 999,
                  }}
                >
                  <MenuIcon
                    style={{
                      position: "relative",
                      zIndex: 999,
                    }}
                    className="toggle"
                    color={"black"}
                  />
                </IconButton>

                <div style={{ padding: "0px" }}>
                  <AvatarTopDiv
                    style={{ zIndex: 1, width: "100%", right: "0" }}
                  >
                    <div
                      id="basic-button"
                      className="Avatar-top-div"
                      aria-controls={opens ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={opens ? "true" : undefined}
                    >
                      <div>
                        <p>
                          <Header
                            headername={getHeaderName(location.pathname)}
                          />
                        </p>
                      </div>
                     
{!hideRoutes.includes(location.pathname) && (
  <div className="tasks__button__container">
    <button className="this__week__button">
      <div onClick={toggleDropdown} className="this__week__text_container">
        <p className="this__Week_text">This Week</p>
        <div className="dropdown-container">
          <img src="/Images/Dashboard/dropdown.svg" alt="Dropdown" />
          {isOpen && (
            <div className="dropdown-content">
              <ul>
                <li>Action 1</li>
                <li>Action 2</li>
                <li>Action 3</li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </button>
    <button className="add_task_button">
      <div className="add_task_text_container">
        <img src="/Images/Dashboard/plusicon.svg" alt="Add Icon" />
        <p className="add__task__text">
          {location.pathname === "/taskgroup" ? "Add Group" : "Add Task"}
        </p>
      </div>
    </button>
  </div>
)}
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={opens}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      ></Menu>
                    </div>
                  </AvatarTopDiv>
                </div>
                {/* <Typography variant="h6" noWrap component="div">
            Persistent drawer
          </Typography> */}
              </Toolbar>
            </AppBar>
            {!isMobile && (
              <Drawer
                PaperProps={{
                  sx: {
                    backgroundColor: " #fff",
                    borderRight: "2px solid #BCBFCC99",
                  },
                }}
                style={{ zIndex: 1, position: "relative" }}
                sx={{
                  width: drawerWidth,
                  flexShrink: 0,

                  "& .MuiDrawer-paper": {
                    width: drawerWidth,
                    boxSizing: "border-box",
                  },
                }}
                variant="persistent"
                anchor="left"
                open={open}
              >
                <div>
                  <DrawerHeader>
                    <img
                      src="/Images/Dashboard/logo.svg"
                      // width={"30px"}
                      // height={"30px"}
                      className="ms-3"
                      alt="Logo"
                    />
                    <IconButton
                      onClick={handleDrawerClose}
                      style={{ color: "#000" }}
                    >
                      {theme.direction === "ltr" ? (
                        <ChevronLeftIcon />
                      ) : (
                        <ChevronRightIcon />
                      )}
                    </IconButton>
                  </DrawerHeader>
                  {/* <Divider /> */}
                  <List className="List-div">
                    {filteredMenuItems?.map(
                      ({ text, path, disabled }, index) => (
                        <React.Fragment key={index}>
                          <ListItem
                            selected={index === selectedItem}
                            key={text}
                            disabled={disabled}
                            disablePadding
                            divider={false}
                            sx={{
                              fontSize: "14px",
                              fontWeight: "500",
                              lineHeight: "22.4px",
                            }}
                          >
                            <ListItemButton
                              component={Link}
                              to={path}
                              onClick={() => handleListItemClick(index)}
                              selected={index === selectedItem}
                              className={
                                index === selectedItem
                                  ? "list-item-active"
                                  : "list-item"
                              }
                              sx={{
                                "& .MuiListItemText-root": {
                                  fontSize: "14px",
                                },
                                "& .MuiListItemText-primary": {
                                  fontSize: "14px",
                                },
                              }}
                            >
                              <ListItemIcon
                                minWidth={"24px"}
                                className="list-icons"
                                selected={index === selectedItem}
                                sx={{
                                  "& ..MuiListItemIcon-root": {
                                    minWidth: "24px",
                                  },
                                }}
                              >
                                <img
                                  src={
                                    index === selectedItem
                                      ? activeDashicons[index]
                                      : dashicons[index]
                                  }
                                  alt={`Icon ${index + 1}`}
                                />
                              </ListItemIcon>
                              <ListItemText
                                selected={index === selectedItem}
                                className={
                                  index === selectedItem
                                    ? "list-item-active"
                                    : "list-item"
                                }
                                primary={text}
                              />
                            </ListItemButton>
                          </ListItem>
                          {text === "Tasks" && (
                            <div
                              className="task__main__div"
                              style={{ paddingLeft: "30px", marginTop: "5px" }}
                            >
                              <div>
                                <ListItemButton
                                  component={Link}
                                  to="/"
                                  sx={{
                                    "& .MuiListItemText-root": {
                                      fontSize: "14px",
                                    },
                                    "& .MuiListItemText-primary": {
                                      fontSize: "14px",
                                      color: selected ? "#1e1e24" : "#BcBFCC",
                                    },
                                    display: "flex",
                                    gap: "12px",
                                    alignItems: "center",
                                  }}
                                  onClick={handleSelection}
                                >
                                  <img
                                    src={
                                      selected
                                        ? "/Images/Dashboard/newtaskbright.svg"
                                        : "/Images/Dashboard/newtask.svg"
                                    }
                                    alt="Task Icon"
                                  />
                                  <ListItemText primary="New Task" />
                                </ListItemButton>
                              </div>
                              <div>
                                <ListItemButton
                                  component={Link}
                                  to="/"
                                  sx={{
                                    "& .MuiListItemText-root": {
                                      fontSize: "14px", // Smaller font for sub-items
                                    },
                                    "& .MuiListItemText-primary": {
                                      fontSize: "14px",
                                    },

                                    display: "flex",
                                    gap: "12px",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    src="/Images/Dashboard/featuredtask.svg"
                                    alt=""
                                  />
                                  <ListItemText
                                    primary="Featured Tasks"
                                    selected={index === selectedItem}
                                    className={
                                      index === selectedItem
                                        ? "list-item-active"
                                        : "list-item"
                                    }
                                  />
                                </ListItemButton>
                              </div>
                              <div>
                                <ListItemButton
                                  component={Link}
                                  to="/taskgroup"
                                  sx={{
                                    "& .MuiListItemText-root": {
                                      fontSize: "14px", // Smaller font for sub-items
                                    },
                                    "& .MuiListItemText-primary": {
                                      fontSize: "14px",
                                    },

                                    display: "flex",
                                    gap: "12px",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    src="/Images/Dashboard/taskgroup.svg"
                                    alt=""
                                  />
                                  <ListItemText
                                    primary="Tasks Group"
                                    selected={index === selectedItem}
                                    className={
                                      index === selectedItem
                                        ? "list-item-active"
                                        : "list-item"
                                    }
                                  />
                                </ListItemButton>
                              </div>
                            </div>
                          )}
                        </React.Fragment>
                      )
                    )}
                  </List>

                  {/* <Divider /> */}

                  <List className="List-div">
                    {/* <p className="items-main-heading">Other Menu</p> */}

                    <div>
                      {filteredOtherMenuItems?.map(({ text, path }, index) => (
                        <ListItem
                          key={text}
                          disablePadding
                          selected={index === selectedotherItem}
                        >
                          <ListItemButton
                            component={Link}
                            to={path}
                            selected={index === selectedotherItem}
                            onClick={() => handleOtherListItemClick(index)}
                            className={
                              index === selectedotherItem
                                ? "list-item-active"
                                : "list-item"
                            }
                            sx={{
                              "& .MuiListItemText-root": {
                                fontSize: "14px",
                              },
                              "& .MuiListItemText-primary": {
                                fontSize: "14px",
                              },
                            }}
                          >
                            <ListItemIcon
                              minWidth={"24px"}
                              className="list-icons"
                              selected={index === selectedotherItem}
                              sx={{
                                "& ..MuiListItemIcon-root": {
                                  minWidth: "24px",
                                },
                              }}
                            >
                              <img
                                src={otherdashicons[index]}
                                alt={`Icon ${index + 1}`}
                              />
                            </ListItemIcon>
                            <ListItemText
                              className={
                                index === selectedotherItem
                                  ? "list-item-active"
                                  : "list-item"
                              }
                              primary={text}
                            />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </div>
                  </List>
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => handleLogoutMain()}
                  className="Logout-div"
                >
                  <img src="/Images/Dashboard/logout.svg" alt="Logout-icon" />

                  <p className="Logout-txt">logout</p>
                </div>
              </Drawer>
            )}

            {isMobile && (
              <Drawer
                PaperProps={{
                  sx: {
                    backgroundColor: "#fff",
                  },
                }}
                sx={{
                  width: 250,
                  flexShrink: 0,
                  "& .MuiDrawer-paper": {
                    width: drawerWidth,
                    boxSizing: "border-box",
                  },
                }}
                variant="persistent"
                anchor="left"
                open={mobileOpen}
                onClose={handleDrawerToggle}
              >
                <div>
                  <DrawerHeader>
                    <img
                      src="/Images/Dashboard/logo.svg"
                      // width={"30px"}
                      // height={"30px"}
                      className="ms-3"
                      alt="Logo"
                    />
                    <IconButton
                      onClick={handleDrawerClose}
                      style={{ color: "#000" }}
                    >
                      {theme.direction === "ltr" ? (
                        <ChevronLeftIcon />
                      ) : (
                        <ChevronRightIcon />
                      )}
                    </IconButton>
                  </DrawerHeader>
                  {/* <Divider /> */}
                  <List className="List-div">
                    {filteredMenuItems?.map(
                      ({ text, path, disabled }, index) => (
                        <React.Fragment key={index}>
                          <ListItem
                            key={text}
                            disablePadding
                            disabled={disabled}
                            selected={index === selectedItem}
                            className={
                              index === selectedItem
                                ? "list-item-active"
                                : "list-item"
                            }
                          >
                            <ListItemButton
                              component={Link}
                              to={path}
                              onClick={() => handleListItemClick(index)}
                              selected={index === selectedItem}
                              className={
                                index === selectedItem
                                  ? "list-item-active"
                                  : "list-item"
                              }
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "start",
                                "& .MuiListItemText-root": {
                                  fontSize: "14px",
                                },
                                "& .MuiListItemText-primary": {
                                  fontSize: "14px",
                                },
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "12px",
                                }}
                              >
                                <ListItemIcon
                                  minWidth={"24px"}
                                  className="list-icons"
                                  selected={index === selectedItem}
                                  sx={{
                                    "& ..MuiListItemIcon-root": {
                                      minWidth: "24px",
                                    },
                                  }}
                                >
                                  <img
                                    src={
                                      index === selectedItem
                                        ? activeDashicons[index]
                                        : dashicons[index]
                                    }
                                    alt={`Icon ${index + 1}`}
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  selected={index === selectedItem}
                                  className={
                                    index === selectedItem
                                      ? "list-item-active"
                                      : "list-item"
                                  }
                                  primary={text}
                                  onClick={
                                    index === 1
                                      ? () => handle_accordian("A")
                                      : null
                                  }
                                />
                              </div>
                              {/* {index === 1 && active === "A" && (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    marginLeft: "40px",
                                    marginTop: "8px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "12px",
                                      padding: "12px 16px",
                                    }}
                                  >
                                    <img
                                      src="/Images/Dashboard/course.svg"
                                      alt=""
                                    />
                                    <p className="drawr-option-text1">
                                      Courses
                                    </p>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "12px",
                                      padding: "12px 16px",
                                    }}
                                  >
                                    <img
                                      src="/Images/Dashboard/Report.svg"
                                      alt=""
                                    />
                                    <p className="drawr-option-text12">
                                      Market Reports
                                    </p>
                                  </div>
                                </div>
                              )} */}
                              {/* {disabled && (
                          <img src="/Images/clock.svg" alt={`Disabled Image`} />
                        )}{" "} */}
                            </ListItemButton>
                          </ListItem>
                          {text === "Settings" && (
                            <div
                              style={{ paddingLeft: "30px", marginTop: "5px" }}
                            >
                              <div>
                                <ListItemButton
                                  component={Link}
                                  // to="/sub-setting-1"
                                  sx={{
                                    "& .MuiListItemText-root": {
                                      fontSize: "14px", // Smaller font for sub-items
                                    },
                                    "& .MuiListItemText-primary": {
                                      fontSize: "14px",
                                    },
                                    display: "flex",
                                    gap: "12px",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    src="/Images/Dashboard/subicon.svg"
                                    alt=""
                                  />
                                  <ListItemText
                                    primary="API Management"
                                    selected={index === selectedItem}
                                    className={
                                      index === selectedItem
                                        ? "list-item-active"
                                        : "list-item"
                                    }
                                  />
                                </ListItemButton>
                              </div>
                              <div>
                                <ListItemButton
                                  component={Link}
                                  // to="/sub-setting-2"
                                  sx={{
                                    "& .MuiListItemText-root": {
                                      fontSize: "14px", // Smaller font for sub-items
                                    },
                                    "& .MuiListItemText-primary": {
                                      fontSize: "14px",
                                    },

                                    display: "flex",
                                    gap: "12px",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    src="/Images/Dashboard/subicon.svg"
                                    alt=""
                                  />
                                  <ListItemText
                                    primary="User Management"
                                    selected={index === selectedItem}
                                    className={
                                      index === selectedItem
                                        ? "list-item-active"
                                        : "list-item"
                                    }
                                  />
                                </ListItemButton>
                              </div>
                            </div>
                          )}
                        </React.Fragment>
                      )
                    )}
                  </List>

                  {/* <Divider /> */}

                  <List className="List-div">
                    <p className="items-main-heading">Other Menu</p>

                    <div>
                      {filteredOtherMenuItems?.map(({ text, path }, index) => (
                        <ListItem
                          key={text}
                          disablePadding
                          selected={index === selectedotherItem}
                        >
                          <ListItemButton
                            component={Link}
                            to={path}
                            onClick={() => handleOtherListItemClick(index)}
                            selected={index === selectedotherItem}
                            className={
                              index === selectedotherItem
                                ? "list-item-active"
                                : "list-item"
                            }
                            sx={{
                              "& .MuiListItemText-root": {
                                fontSize: "14px",
                              },
                              "& .MuiListItemText-primary": {
                                fontSize: "14px",
                              },
                            }}
                          >
                            <ListItemIcon
                              className="list-icons"
                              sx={{
                                "& ..MuiListItemIcon-root": {
                                  minWidth: "24px",
                                },
                              }}
                              minWidth={"24px"}
                            >
                              <img
                                src={otherdashicons[index]}
                                alt={`Icon ${index + 1}`}
                              />
                            </ListItemIcon>
                            <ListItemText
                              selected={index === selectedotherItem}
                              className={
                                index === selectedotherItem
                                  ? "list-item-active"
                                  : "list-item"
                              }
                              primary={text}
                            />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </div>
                  </List>
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => handleLogoutMain()}
                  className="Logout-div"
                >
                  <img src="/Images/Dashboard/logout.svg" alt="Logout-icon" />

                  <p className="Logout-txt">logout</p>
                </div>
              </Drawer>
            )}

            <Main
              style={{ padding: 0 }}
              open={open}
              sx={
                {
                  // paddingLeft: isMobile ? "50px" : "28px",
                }
              }
            >
              <DrawerHeader />
              {children}
            </Main>
          </Box>
        )}
      </ThemeProvider>
      {/* <Web3Modal show={wallet} setModalShows={SetWallet} /> */}
    </>
  );
}
