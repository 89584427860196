import React from "react";
import {useState} from 'react'
import { useNavigate } from "react-router-dom";
import "./EditTaskGroup.css";
import { DropzoneArea } from "material-ui-dropzone";
function EditTaskGroup() {
    const [img, setImg] = useState("");
    const [imageSource, setImageSource] = useState();
    const navigate = useNavigate();
    function handleTaskClick() {
      navigate("/taskgroup");
    }
  return (
    <div className="edit__task__group__main_container">
      <div className="edit__task__group__main_container1">
        <div className="edit__task__group__main_container2">
          <p className="edit__taskgroup__task_heading">Add Task</p>
          <div className="edit_task_group_form_container">
          <div className="edit_task_group_form_container1">
            <p className="edit_task_group_form_label">
            Task Title 
            </p>
            <input
                className="edit__taskgroup__form_placeholder"
                type="text"
                placeholder="Enter Title of the Task"
              />
          </div>
          <div className="edit_task_group_form_container1">
            <p className="edit_task_group_form_label">
            Task Description
            </p>
            <textarea
                className="edit__taskgroup__form_placeholder1"
                type="text"
                placeholder="Enter Title of the Task"
              />
          </div>

        </div>
        <div className="edit__taskgroup__button__container">
            <button className="edit__taskgroup__publish__button">Publish</button>
            <button onClick={handleTaskClick} className="edit__taskgroup__cancel__button">Cancel</button>
          </div>
        </div>
        <div className="upload__task__container">
          <div className="upload__task__container1">
            <p className="upload__task__heading">
            Upload Task Image
            </p>
            <p className="upload__task__imagesize__heading">
            Image size: 52x52
            </p>
          </div>
          <DropzoneArea
                  id="upload_image"
                  filesLimit={1}
                  acceptedFiles={["image/*"]}
                  maxFileSize={5000000}
                  showAlerts={["error", "info"]}
                  dropzoneText="Drag & Drop or"
                  value={img}
                  onChange={(files) => {
                    setImageSource(files[0]);
                  }}
                  onDropRejected={(files) => {
                    alert("File rejected: " + files[0].name);
                  }}
                />
        </div>
      </div>
    </div>
  );
}

export default EditTaskGroup;
