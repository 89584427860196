import React from "react";
import "./Refferals.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function Refferals() 
{
  const referralData = [
    {
      id: 1,
      userImage: '/Images/Refferals/userImage.svg',
      userName: 'George',
      team: '15',
      rank: 'Gold L1',
      totalXMAG: 15000,
      status: 'Processing',
    },
    {
      id: 1,
      userImage: '/Images/Refferals/userImage.svg',
      userName: 'George',
      team: '15',
      rank: 'Gold L1',
      totalXMAG: 15000,
      status: 'Completed',
    },
    {
      id: 1,
      userImage: '/Images/Refferals/userImage.svg',
      userName: 'George',
      team: '15',
      rank: 'Gold L1',
      totalXMAG: 15000,
      status: 'Processing',
    },
    {
      id: 1,
      userImage: '/Images/Refferals/userImage.svg',
      userName: 'George',
      team: '15',
      rank: 'Gold L1',
      totalXMAG: 15000,
      status: 'Completed',
    },   {
      id: 1,
      userImage: '/Images/Refferals/userImage.svg',
      userName: 'George',
      team: '15',
      rank: 'Gold L1',
      totalXMAG: 15000,
      status: 'Processing',
    },   {
      id: 1,
      userImage: '/Images/Refferals/userImage.svg',
      userName: 'George',
      team: '15',
      rank: 'Gold L1',
      totalXMAG: 15000,
      status: 'Completed',
    },   {
      id: 1,
      userImage: '/Images/Refferals/userImage.svg',
      userName: 'George',
      team: '15',
      rank: 'Gold L1',
      totalXMAG: 15000,
      status: 'Processing',
    },   {
      id: 1,
      userImage: '/Images/Refferals/userImage.svg',
      userName: 'George',
      team: '15',
      rank: 'Gold L1',
      totalXMAG: 15000,
      status: 'Completed',
    },   {
      id: 1,
      userImage: '/Images/Refferals/userImage.svg',
      userName: 'George',
      team: '15',
      rank: 'Gold L1',
      totalXMAG: 15000,
      status: 'Processing',
    },   {
      id: 1,
      userImage: '/Images/Refferals/userImage.svg',
      userName: 'George',
      team: '15',
      rank: 'Gold L1',
      totalXMAG: 15000,
      status: 'Completed',
    },
    {
      id: 1,
      userImage: '/Images/Refferals/userImage.svg',
      userName: 'George',
      team: '15',
      rank: 'Gold L1',
      totalXMAG: 15000,
      status: 'Processing',
    }, {
      id: 1,
      userImage: '/Images/Refferals/userImage.svg',
      userName: 'George',
      team: '15',
      rank: 'Gold L1',
      totalXMAG: 15000,
      status: 'Completed',
    }, {
      id: 1,
      userImage: '/Images/Refferals/userImage.svg',
      userName: 'George',
      team: '15',
      rank: 'Gold L1',
      totalXMAG: 15000,
      status: 'Processing',
    }, {
      id: 1,
      userImage: '/Images/Refferals/userImage.svg',
      userName: 'George',
      team: '15',
      rank: 'Gold L1',
      totalXMAG: 15000,
      status: 'Completed',
    }, {
      id: 1,
      userImage: '/Images/Refferals/userImage.svg',
      userName: 'George',
      team: '15',
      rank: 'Gold L1',
      totalXMAG: 15000,
      status: 'Processing',
    }, {
      id: 1,
      userImage: '/Images/Refferals/userImage.svg',
      userName: 'George',
      team: '15',
      rank: 'Gold L1',
      totalXMAG: 15000,
      status: 'Completed',
    },

  ];
  const getStatusStyle = (status) => {
    switch (status) {
      case 'Processing':
        return { color: '#FF9900' };
      case 'Completed':
        return { color: '#04c182' };
      default:
        return { color: 'black' };
    }
  };
  
  return (
    <div className="referals__main__container">
      <TableContainer
        className="refferals__table__main__container"
        component={Paper}
        style={{
          backgroundColor: "rgba(188,191,204, 0.1)",
          boxShadow: "none",
        }}
      >
        <Table
          className="for-border"
          sx={{ minWidth: 650, backgroundColor: "rgba(188,191,204, 0.1)" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell className="refferals__head__main">#</TableCell>
              <TableCell className="refferals__head__main">User Image</TableCell>
              <TableCell className="refferals__head__main">User</TableCell>
              <TableCell className="refferals__head__main">Team</TableCell>
              <TableCell className="refferals__head__main">Rank</TableCell>
              <TableCell className="refferals__head__main">Total xMAG</TableCell>
              <TableCell className="refferals__head__main">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="refferals__table__body">
          {referralData.map((referral, index) => (
            <TableRow key={referral.id}>
              <TableCell className="refferals__index_1">{index + 1}</TableCell>
              <TableCell className="reffrals__user__image">
                <img className="user__image_1"
                  src={referral.userImage}
                  alt={`${referral.userName}'s avatar`}
                />
              </TableCell>
              <TableCell  className="refferals__user_name" >{referral.userName}</TableCell>
              <TableCell  className="refferals__team" >{referral.team}</TableCell>
              <TableCell  className="refferals__rank">{referral.rank}</TableCell>
              <TableCell className="refferals__xmag">{referral.totalXMAG}</TableCell>
              <TableCell  className="refferals__status" style={getStatusStyle(referral.status)}>
                {referral.status}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default Refferals;
